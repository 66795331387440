<template>
  <v-card v-if="loaded" class="pa-5" elevation="0">
    <v-row v-for="type of Object.keys(types)" :key="type">
      <v-col cols="12">
        <h2 class="title font-weight-bold">{{ `${type.slice(0, 1).toUpperCase()}${type.slice(1)}` }}</h2>
      </v-col>

      <v-col cols="12">
        <v-data-table
          :headers="headers"
          :items="types[`${type}`] || []"
          :single-expand="false"
          item-key="date"
          show-expand
          class="elevation-1"
        >
          <template v-slot:item.date="{ item }">
            {{ getFormattedDate(item.date) }}
          </template>

          <template v-slot:expanded-item="{ headers, item }">
            <td :colspan="headers.length">
              <v-row align="center" justify="space-between" class="pa-3">
                <v-col cols="6">
                  <json-viewer :value="item.dataBefore || {}"></json-viewer>
                </v-col>
                <v-col cols="6">
                  <json-viewer :value="item.data || {}"></json-viewer>
                </v-col>
              </v-row>
            </td>
          </template>
        </v-data-table>
      </v-col>
    </v-row>
  </v-card>
</template>

<script>
import { db } from '@/plugins/firebase';

export default {
  name: 'LogsReport',
  data() {
    return {
      expanded: [],
      headers: [
        {
          text: 'Author',
          align: 'start',
          value: 'author',
        },
        { text: 'Date', align: 'start', value: 'date' },
        { text: 'Project', align: 'start', value: 'project' },
        { text: 'Description', align: 'start', value: 'description' },
        { text: '', value: 'data-table-expand' },
      ],
      types: {},
      loaded: false,
    };
  },
  created() {
    const self = this;

    db.ref(`logs`).once('value', function (snapshot) {
      snapshot.forEach(function (childSnapshot) {
        const data = childSnapshot.val();
        let records = [];

        for (let record of Object.keys(data)) {
          records.push(data[`${record}`]);
        }

        self.types[`${childSnapshot.key}`] = records.reverse();
      });

      self.loaded = true;
    });
  },
  methods: {
    getFormattedDate(date) {
      return new Date(date).toLocaleDateString('cs', {
        year: 'numeric',
        month: 'long',
        day: 'numeric',
        hour: '2-digit',
        minute: '2-digit',
        second: '2-digit',
      });
    },
  },
};
</script>

<style lang="scss" scoped></style>
